module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"forms.nono.so","short_name":"forms.nono.so","start_url":"/","background_color":"#FFFFFF","theme_color":"#6F74FE","display":"standalone","icon":"icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e820a306c732b90515989dada9995a97"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-9563NNCH93"},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"da268076-7d26-4321-aceb-40b2fbef7454","enableDuringDevelop":false,"defer":false,"enableImprovedAccessibility":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
